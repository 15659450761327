// @ts-nocheck
/* eslint-disable */

import { getMeta } from 'lambda-dom'

const gtmSource = 'https://servercontainer.basiclabel.nl/ugaqykyu.js?id='
const gtmKey = getMeta('gtm-key')

if (! gtmKey) {
    console.warn('No GTM key found')
} else {
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        gtmSource+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer', gtmKey);
}
